<template>
  <div id="wrapper">
    <img id="logo" src="../assets/Compadre.png" />
    <div id="nav-links">
      <!-- <p class="link">Home</p>
      <p class="link"><a href="#container" class="nav-link">Features</a></p>
      <p class="link"><a href="#join-box" class="nav-link">Join the Waitlist</a></p> -->
    </div>
  </div>
</template>

<script setup lang="ts" >

</script>

<style>

#wrapper {
  height: 90px;
  display: flex;
  justify-content: space-between;
  background-color: #FFFFFF;
  padding: 5px;
  max-width: 1400px;
  margin: auto;

}

#logo {
  width: 200px;
  height: 60px;
  margin-top: 5px;
}

#nav-links {
  display: flex;
}

.nav-link {
  text-decoration: none;
  color: #464255;

}

.link {
  margin: 20px;
}


</style>