<template>
    <div id="container3">
        <h1 id="main-heading3">Everything right at your client’s fingertips.</h1>
        <p id="subheading3">Keep your clients accountable and up-to-date with the Compadre client mobile app. Fully featured-packed for all of their needs.</p>
        <div id="features-wrapper3">
            <div class="card-background3" id="top-left-card">
                <ul class="bullets3">
                    <div id="box1-bullet">
                        <img src="../assets/features3-pictures/checkmark-blue.svg" />
                        <p>Complete forms</p>
                    </div>
                    <div id="box1-bullet">
                        <img src="../assets/features3-pictures/checkmark-blue.svg" />
                        <p>Check-off todos</p>
                    </div>
                    <div id="box1-bullet">
                        <img src="../assets/features3-pictures/checkmark-blue.svg" />
                        <p>View progress</p>
                    </div>
                    <h4 class="features3-heading">All with ease.</h4>
                </ul>
                <img id="box1img" src="../assets/features3-pictures/box1img.png" />
            </div>
            <div class="card-background3" id="bottom-left-card">
                <img id="box2img" src="../assets/features3-pictures/box2img.png" />
                <ul id="bullets3-2">
                    <div id="box1-bullet">
                        <img src="../assets/features3-pictures/checkmark-green.svg" />
                        <p>Share files</p>
                    </div>
                    <div id="box1-bullet">
                        <img src="../assets/features3-pictures/checkmark-green.svg" />
                        <p>Make Payments</p>
                    </div>
                    <div id="box1-bullet">
                        <img src="../assets/features3-pictures/checkmark-green.svg" />
                        <p>Get reminders</p>
                    </div>
                    <h4 class="features3-heading">All in one app.</h4>
                </ul>
            </div>
            <div id="right-card">
                <p class="features3-subheading">Compadre</p>
                <h4 class="features3-heading" id="available">Available on mobile and the web</h4>
                <div id="app-pics">
                    <div id="logos">
                        <img id="applogo" src="../assets/features3-pictures/applogo.png" />
                        <img id="stores" src="../assets/features3-pictures/stores.png" />
                    </div>
                    <img id="phones" src="../assets/features3-pictures/phones.png" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

</script>

<style>

#container3 {
    background-image: url("../assets/features3-pictures/background.png");
    background-size: 400% 100%;
    background-repeat: no-repeat;
    text-align: center;
    background-position: center;
    padding-bottom: 0;
}

#main-heading3 {
    max-width: 1300px;
    color: #64DF99; 
    font-size: 48px;
    line-height: 60px;
    padding-top: 80px;
    margin: auto;
}

#subheading3 {
    max-width: 613px;
    font-size: 18px;
    line-height: 30px;
    margin: 16px auto auto auto;
}

#features-wrapper3 {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    justify-content: center;
    column-gap: 28px;
    row-gap: 28px;
    margin-top: 76px;
    padding-bottom: 160px;
}

.card-background3 {
    width: 543px;
    height: 193px;
    background-color: white;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 20px;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.062));
}

#top-left-card {
    grid-column: 1 / 2; /* Span the first column */
    grid-row: 1 / 2; /* Span the first row */
    border-radius: 20px 0 0 0;
}

#bottom-left-card {
    grid-column: 1 / 2; /* Span the first column */
    grid-row: 2 / 3; /* Span the second row */
    border-radius: 0 0 0 20px;
}

#right-card {
    grid-column: 2 / 3; /* Span the second column */
    grid-row: 1 / 3; /* Span both rows */
    width: 564px;
    height: 423px;
    background-color: white;
    padding: 30px 45px 45px 45px;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.062));
    border-radius: 0 20px 20px 0;
}

.features3-heading {
    text-align: left;
    font-size: 22px;
    margin: 0;
    line-height: 40px;
}

.bullets3 {
    padding: 0;
    margin-left: 8px;
}

#box1img {
    width: 300px;
    margin-left: 20px;
}

#box1-bullet {
    display: flex;
    text-align: left;
    font-size: 22px;
    line-height: 10px;
    gap: 7px;
}

#box2img {
    width: 290px;
}

#applogo {
    width: 200px;
}

#stores {
    width: 180px;
    margin-top: 20px;
}

#phones {
    width: 310px;
    margin: 0 0 110px 40px;
}

#logos {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#app-pics {
    display: flex;
    align-items: center;
    height: 600px
}

.features3-heading {
    font-size: 22px;
    font-weight: 700;
    text-align: left;
    max-width: 225px;
    line-height: 40px;
}

#available {
    line-height: 28px;
    margin-bottom: -118px;
    padding: 3px;
}

.features3-subheading {
    margin: 0;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 3px;
}

@media (max-width: 1330px) {
#container3 {
    background-size: 200% 100%;
}
#main-heading3 {
    padding-top: 100px;
    max-width: 600px;
}
.card-background3 {
    width: 400px;
    height: 193px;
}
#right-card {
    width: 475px;
    height: 423px;
    background-color: white;
    padding: 30px 45px 50px 35px;

}
#phones {
    width: 250px;
}
#box1img {
    width: 250px;
    margin-left: 20px;
}

#box1-bullet {
    display: flex;
    text-align: left;
    font-size: 16px;
    line-height: 18px;
    gap: 7px;
    margin: -15px 0 -10px 0;
}

.features3-subheading {
    margin-bottom: -20px;
}

.features3-heading {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    max-width: 225px;
    line-height: 20px;
    margin-top: 15px;
}

#box2img {
    width: 250px;
    margin-right: -20px;
    margin-left: -10px;
}

#applogo {
    width: 200px;
}

#stores {
    width: 180px;
    margin-top: 20px;
}
}

@media (max-width: 1098px) {
#container2 {
    text-align: center;
    margin-bottom: 0;
}
#container3 {
    background-size: 300% 100%;

}
#main-heading3 {
    padding-top: 120px;
    max-width: 600px;
}
#features-wrapper3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#top-left-card {
    grid-column: 1 / 1; /* Span the first column */
    grid-row: 1 / 3; /* Span the first row */
    border-radius: 24px;
    justify-content: space-evenly;
}
#bottom-left-card {
    grid-column: 1 / 1; /* Span the first column */
    grid-row: 2 / 3; /* Span the second row */
    border-radius: 24px;
    justify-content: space-evenly;
}

#right-card {
    grid-column: 1 / 1; /* Span the second column */
    grid-row: 3 / 3; /* Span both rows */
}
.card-background3 {
    width: 550px;
}
#right-card {
    width: 505px;
    border-radius: 24px;
}
#box1img {
    width: 290px;
}
#box1-bullet {
    font-size: 20px;
}
#box2img {
    width: 300px;
}
}

@media (max-width: 640px) {
#main-heading3 {
    padding: 120px 30px 0px 30px;
    font-size: 38px;
    line-height: 50px;
}
#subheading3 {
    padding: 0 30px 0 30px;
}
.card-background3 {
    display: flex;
    justify-content: left;
    flex-direction: column;
    width: 300px;
    height: fit-content;
    padding: 30px;
}

#features-wrapper3 {
    margin-top: 30px;
}
.features3-heading {
    font-size: 24px;
    font-weight: 700;
    max-width: 100%;
    line-height: 20px;
    margin-top: 30px;
    text-align: center;
}
.features3-subheading {
    font-size: 20px;
    max-width: 100%;
    line-height: 20px;
    margin-top: 15px;
    text-align: center;
}
#right-card {
    width: 275px;
    height: fit-content;
    border-radius: 24px;
}
#box1-bullet {
    font-size: 28px;
}
#box1img {
    width: 330px;
    margin-top: 15px;
    margin-left: 0;
}
#box2img {
    width: 330px;
}
.bullets3 {
    margin-left: 0px;
}
#bullets3-2 {
    padding: 0;
}
#applogo {
    width: 200px;
    margin-top: 30px;
}

#stores {
    width: 200px;
    margin-top: 20px;
}

#phones {
    width: 250px;
    margin: 0;
}

#logos {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#app-pics {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    height: auto;
}
#available {
    margin: 30px 0 20px 0;
}

}

@media (max-width: 400px) {
#main-heading3 {
    font-size: 32px;
    line-height: 40px;
}
.card-background3 {
    width: 240px;
}
#right-card {
    width: 215px;
    height: fit-content;
    border-radius: 24px;
}
#box1-bullet {
    font-size: 24px;
}
#box1img {
    width: 290px;
    margin-top: 15px;
    margin-left: 0;
}
#box2img {
    width: 290px;
}
#phones {
    width: 200px;
    margin: 0;
}
#features-wrapper3 {
    padding-bottom: 50px;
}
}
</style>