<template>
    <div id="container4">
        <div id="join-box">
            <h1 id="join-waitlist-header">Join the Waitlist</h1>
            <h3 id="join-waitlist-subheader">Interested in Compadre? Join the countless other coaches for:</h3>
            <ul class="join-bullets">
                <li class="bullet-item-join">Frequent development updates</li>
                <li class="bullet-item-join">Exclusive free early access to beta testing</li>
                <li class="bullet-item-join">Have a say in the development process by sending your feedback</li>
                <li class="bullet-item-join">Massive exclusive discounts upon official release (as a thank you!)</li>
            </ul>
            <text-input
            v-model="email"
            placeholder="example@mail.com"
            label="Email:"
            type="email"
            id="join-email-field"
            />
            <button
                v-if="showButton"
                @click="addEmail" 
                id="get-invite"
            >Get your invite</button>
            <p
                id="msg1"
                style="text-align: center;"
            >{{ msg }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import MailerLite from '@mailerlite/mailerlite-nodejs';
import TextInput from '@/components/TextInput.vue';
import { ref } from 'vue'

const email = ref("");
const msg = ref();
const showButton = ref(true);

const mailerlite = new MailerLite({
  api_key: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiYjhkMWFjYmRhNGYzZjcxMmJjOTBkZDk1MTVjNmU5NGI0ZWMyMGRjOGVmOGI1ZmJiZTAwYzlhNzMyZjA1MDZkMzkyMWI1OTYxZWQ1YzhkMTciLCJpYXQiOjE3MTgzNjU3OTYuMjEyMDI5LCJuYmYiOjE3MTgzNjU3OTYuMjEyMDMxLCJleHAiOjQ4NzQwMzkzOTYuMjA4OTI2LCJzdWIiOiI5OTcxMzkiLCJzY29wZXMiOltdfQ.ZNgeSrtAD73ppNDKsK39KPkyMwALmU0vYD5vi_QSSgSI8ofdaCeyCNLIHLEEasaOwFx58FPu8E-8ffv6tHJchA3poqPz5_YwB8sqNtSqqWEyQMTBhzm0VADEuUpI584RB7HDK5l33F2-cdB_IY_AhX6odbzYGUApmoZvxSayyEYS9ylm3tTlug1ieFuUJiklX7yWhxIxnVTdiUE1SP1X_IjQLKX0ZekL6Tj_aM3RdWmr9L_ibRA5xTHhgBCWcGZ-imD1JwPj456khUmoongODZ-BEC-t-Xi0I_wQOE7s7r2ia--EDuaPeX9oGLYwY-8iIqualanIT8H7dWOV6WiKXauQgEEEYf3nIwi3YnPh3BBg16CqskTvpSypi1ZBrw174pSSigJcrLbS0igPR52JD2v4ZTV_H0nOnE5PzouYVThRiq3b90dVn7aZPcICKZ7Sujth3qHbcrwj4BcFDhBtlIsODi_2L2r1PKtrjIuhHKmcgaSP0JZK46W6kRLHLjvr5klkyawBmShkCMSRnPJEw2GZQCgZNluZI7pSiVlYTpMvYU7o4F6dKG_mbE6W8xIQyGkvltNcs-NMXl3inf4YOKxTtUbxWxH3ENUuSwv57zpK8JDBi7pY8f3hXxVcAb_btlJsb0jYVli--Bcq4wJT1t9nciCZDVlcY-hFwLp0te0"
});

const validateEmail = (email: String) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const addEmail = async () => {
    const text = document.getElementById("msg") as HTMLElement;
    if (validateEmail(email.value)) {
        msg.value = "Thank you for signing up! You'll get updates soon.";
        text.style.setProperty('color', 'green'); // Set color to green for valid email
        showButton.value = false;
        console.log(email.value);

        // Prepare subscriber data
        const subscriberData = {
            email: email.value,
            subscribed_at: generateDatabaseDateTime(),
        };

        mailerlite.subscribers.createOrUpdate(subscriberData)
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                if (error.response) console.log(error.response.data);
            });
    } else {
        msg.value = "Please enter a valid email";
        text.style.setProperty('color', 'red'); // Set color to red for invalid email
        console.log(email.value);
    }
};

function generateDatabaseDateTime() {
    let datenow = new Date();
    return datenow.toISOString().replace("T"," ").substring(0, 19);
}


</script>

<style>

#container4 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/join-pictures/background.png");
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 80px;
    margin-bottom: 80px;
}

#join-box {
    width: 634px;
    height: fit-content;
    padding: 68px;
    background-color: #FFFFFF;
    filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.101));
    border-radius: 25px;
}

#join-waitlist-header {
    font-size: 56px;
    margin: 0 0 0 0;
    color: #64DF99;
    text-align: center;
}

#join-waitlist-subheader {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    text-align: center;
}

#join-bullets {
    text-align: left;
}

#get-invite {
    width: 100%;
}

@media (max-width: 780px) {
    #join-waitlist-header {
    font-size: 48px;
}
}
@media (max-width: 730px) {
    #join-waitlist-header {
    font-size: 40px;
}
#join-box {
    width: 634px;
    height: fit-content;
    padding: 50px;
    background-color: #FFFFFF;
    filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.101));
    border-radius: 25px;
}
}
@media (max-width: 620px) {
#container4 {
    padding: 10px;
}
#join-waitlist-header {
    font-size: 36px;
}
#join-box {
    width: 80%;
    height: fit-content;
    padding: 30px 20px 30px 20px;
    background-color: #FFFFFF;
    filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.101));
    border-radius: 25px;
}
#join-waitlist-subheader {
    font-size: 16px;
}

.bullet-item-join {
    font-size: 16px;
}
}

@media (max-width: 470px) {
#join-waitlist-header {
    font-size: 30px;
}
#join-box {
    width: 90%;
}
}
</style>