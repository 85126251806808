<template>
    <div id="container2">
        <h1 id="main-heading2">Give your clients next level service.</h1>
        <img id="underline2" src="../assets/hero-pictures/underline.svg" />
        <p id="subheading2">Have access to a wide variety of tools to enhance client success. An organized coach allows for productive clients.</p>
        <div id="features-wrapper2">
            <div class="features2-container">
                <img id="group1" src="../assets/features2-pictures/group1.png" />
                <!-- <div id="todo-pics">
                    <img id="avatar3" src="../assets/features2-pictures/avatar-3.png" />
                    <img id="blob3" src="../assets/features2-pictures/blob-3.png" />
                    <img id="dataList1" src="../assets/features2-pictures/dataList-1.png" />
                    <img id="dataList2" src="../assets/features2-pictures/dataList-2.png" />
                </div> -->
                <h3 class="features2-heading">Interactive Todo Lists</h3>
                <p class="features2-subtext">Keep track of your clients’ tasks and action steps.</p>
                <ul class="features2-bullets">
                    <li class="features2-subtext">Set single time or recurring todos</li>
                    <li class="features2-subtext">Set due dates</li>
                </ul>
            </div>
            <div class="features2-container">
                <img id="group2" src="../assets/features2-pictures/group2.png" />
                <!-- <div id="forms-pics">
                    <img id="avatar2" src="../assets/features2-pictures/avatar-2.png" />
                    <img id="blob2" src="../assets/features2-pictures/blob-2.png" />
                    <img id="form-pic" src="../assets/features2-pictures/form.png" />
                </div> -->
                <h3 class="features2-heading">Customizable Forms</h3>
                <p class="features2-subtext">Monitor your client’s progress with fillable forms.</p>
                <ul class="features2-bullets">
                    <li class="features2-subtext">Custom text, numerical, and photo fields. </li>
                    <li class="features2-subtext">Single time or recurring with due dates.</li>
                </ul>
            </div>
            <div class="features2-container">
                <img id="group3" src="../assets/features2-pictures/group3.png" />
                <!-- <div id="progress-pics">
                    <img id="avatar1" src="../assets/features2-pictures/avatar-1.png" />
                    <img id="blob1" src="../assets/features2-pictures/blob-1.png" />
                    <img id="barChart" src="../assets/features2-pictures/barChart.png" />
                </div> -->
                <h3 class="features2-heading">Visual Progress Tracking</h3>
                <p class="features2-subtext">Bring your client’s data to life with custom charts.</p>
                <ul class="features2-bullets">
                    <li class="features2-subtext">Transform data collected from recurring forms filled out by your clients into visual graphs.</li>
                </ul>
            </div>
            <div class="features2-container">
                <img id="group4" src="../assets/features2-pictures/group4.png" />
                <!-- <div id="notes-files-pics">
                    <img id="avatar" src="../assets/features2-pictures/avatar.png" />
                    <img id="blob" src="../assets/features2-pictures/blob.png" />
                    <img id="dataList" src="../assets/features2-pictures/dataList.png" />
                </div> -->
                <h3 class="features2-heading">Share Notes and Files</h3>
                <p class="features2-subtext">Distribution of notes and programs is hassle-free.</p>
                <ul class="features2-bullets">
                    <li class="features2-subtext">Keep files organized in shareable folders.</li>
                    <li class="features2-subtext">Support for a wide variety of file types.</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

</script>

<style>
#container2 {
    text-align: center;
    margin-bottom: 80px;
}

#main-heading2 {
    max-width: 1000px;
    font-size: 48px;
    line-height: 60px;
    margin: 100px auto -5px auto;
}

#subheading2 {
    max-width: 600px;
    font-size: 18px;
    line-height: 30px;
    margin: 0px auto auto auto;
}

#features-wrapper2 {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    row-gap: 82px;
    column-gap: 80px;
    margin-top: 51px;
}

.features2-container {
    display: flex;
    flex-direction: column;
    width: 500px;
    text-align: left;
    justify-content: center;
    align-items: center;
}

.features2-heading, .features2-subtext, .features2-bullets {
    color: #464255;
}

.features2-heading {
    font-size: 28px;
    margin: 10px;
}

.features2-subtext {
    font-size: 18px;
    margin: 0px
}

#group1 {
    height: 280px;
    padding: 10px;
}

#group2, #group3, #group4 {
    height: 300px;
}

#underline2 {
    margin: -20px 20px 0 250px;
}

@media (max-width: 1200px) {
#features-wrapper2 {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    row-gap: 52px;
    column-gap: 0px;
    margin-top: 51px;
}
#group1 {
    height: 187px;
    padding: 10px
}

#group2, #group3, #group4 {
    height: 200px;
}
.features2-heading {
    font-size: 24px;
    margin-top: 10px;
}

.features2-subtext {
    font-size: 14px;
    margin: 0px;
}

.features2-bullets {
    max-width: 300px;
}
}

@media (max-width: 935px) {
#main-heading2 {
    font-size: 36px;
    margin: 60px auto -18px auto;
}
#features-wrapper2 {
    grid-template-columns: auto;
}
#group1 {
    width: 500px;
    height:fit-content;
}

#group2, #group3, #group4 {
    width: 400px;
    height:fit-content;
}
.features2-heading {
    font-size: 36px;
    margin-top: 10px;
}

.features2-subtext {
    font-size: 16px;
    margin: 0px;
}

.features2-bullets {
    max-width: 400px;
    list-style-position: outside;
}

#underline2 {
    margin: -20px 20px 0 180px;
    width: 200px;
}
}

@media (max-width: 706px) {
#main-heading2 {
    max-width: 800px;
    font-size: 30px;
    margin: 60px auto -22px auto;
}
#underline2 {
    margin: -20px 20px 0 160px;
    width: 170px;
}
#group1 {
    width: 300px;
    height:fit-content;
}

#group2, #group3, #group4 {
    width: 250px;
    height:fit-content;
}
.features2-heading {
    font-size: 24px;
    margin-top: 10px;
}
}

@media (max-width: 570px) {
#main-heading2 {
    max-width: 400px;
    font-size: 40px;
    margin: 60px auto -8px auto;
    line-height: 50px;
}
#underline2 {
    margin: -20px 20px 0 -150px;
    width: 220px;
}
}

@media (max-width: 479px) {
#main-heading2 {
    max-width: 300px;
    font-size: 30px;
    margin: 60px auto -12px auto;
    line-height: 40px;
}
#subheading2 {
    max-width: 390px;
    font-size: 16px;
    line-height: 28px;
    padding: 10px 20px;
}
#underline2 {
    margin: -20px 20px 0 -110px;
    width: 160px;
}
.features2-subtext {
    max-width: 400px;
    font-size: 14px;
    margin: 0px;
    padding: 0 10px 0 10px;
}
}

@media (max-width: 479px) {
.features2-container {
    width: 100%;
}
#group1 {
    width: 80%;
    height:fit-content;
}

#group2, #group3, #group4 {
    width: 70%;
    height:fit-content;
}
.features2-heading {
    font-size: 24px;
    margin-top: 10px;
}
.features2-subtext {
    padding: 0 20px 0 20px;
}
}

</style>