<template>
  <hero-section class="hidden"></hero-section>
  <features1 class="hidden"></features1>
  <features2 class="hidden"></features2>
  <features3 class="hidden"></features3>
  <join-section class="hidden"></join-section>
</template>

<script setup lang="ts" >
import HeroSection from './views/HeroSection.vue'
import Features1 from './views/Features1.vue'
import Features2 from './views/Features2.vue'
import Features3 from './views/Features3.vue'
import JoinSection from './views/JoinSection.vue'
import { onMounted } from 'vue'

onMounted(() => {
  setTimeout(() => {
    const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        console.log(entry)
        if (entry.isIntersecting) {
            entry.target.classList.add('show');
        }
      });
  });

  const hiddenElements = document.querySelectorAll('.hidden');
  hiddenElements.forEach((el) => observer.observe(el));
  }, 100);
})

</script>

<style>

#app {
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

body{
    overflow-x:hidden;
    overflow-y:hidden;
}

h1, h2, h3, h4, li, p {
  color: #464255
}

.hidden {
  opacity: 0;
  padding-top: 100px;
  transition: all 1.5s;
}

.show {
  padding: 0px;
  opacity: 1;
}


</style>
