<template>
    <p id="label">{{props.label}}</p>
    <div class="field-wrapper">
        <img class="icon" src="../assets/019-message.svg"/>
        <input
        class="field"
        :type="props.type"
        :placeholder="props.placeholder"
        :value="props.modelValue"
        @input="handleInput"
        >
    </div>
    
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
    modelValue: {
        type: String,
        required: true
    },
    placeholder: {
        type: String,
        default: ""
    },
    label: {
        type: String,
        default: 'Label'
    },
    icon: {
        type: String,
        default: ''
    },
    type: {
        type: String,
        default: ''
    }
});

const emit = defineEmits(['update:modelValue'])

const handleInput = (event: Event) => {
    emit('update:modelValue', (event.target as HTMLInputElement).value)
}

</script>

<style>
.field {
    
    border-radius: 15px;
    height: 72px;
    padding: 0 0 0 11px;
    background-color: #F3F2F7;
    color: #464255;
    border: none;
    width: 100%;
    display: flex;
    font-size: 16px;
}

.field::placeholder {
    color: #CCCCCC
}

.field-wrapper {
    display: flex;
    justify-content: center;
    background-color: #F3F2F7;
    border-radius: 15px;
    margin-bottom: 17px;
}

.icon[src=""] {
    width: 0px;
    margin-left: 10px;
}

.icon {
    width: 28px;
    margin-left: 15px;
}

textarea:focus, input:focus{
    outline: none;
}

#label {
    margin-bottom: 5px;
}


</style>