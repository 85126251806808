<template>
    <nav-bar></nav-bar>
    <div class="hidden" id="hero-wrapper">
        <div id="left-content-wrapper">
            <div>
                <h1 id="hero-header">Everything you need as a coach, all in one place.</h1>
                <img id="underline" src="../assets/hero-pictures/underline.svg" />
            </div>
            <p id="hero-subtext">No need for 3, 4, 5+ applications and subscriptions to run your business. Compadre allows you to keep things simple, yet effective all with just one platform.</p>
            <text-input
                v-model="email"
                placeholder="example@mail.com"
                label="Sign Up for the Waitlist:"
                type="email"
                id="input"
            />
            <button
                v-if="showButton"
                @click="addEmail" 
                id="get-invite"
            >Get your invite</button>
            <p
                id="msg"
                style="text-align: center;"
            >{{ msg }}</p>
        </div>
        <img id="hero-pic" src="../assets/hero-pictures/heropic.webp" />
    </div>
</template>

<script setup lang="ts" >
import { ref } from 'vue';
import TextInput from '../components/TextInput.vue';
import NavBar from '../components/Navbar.vue'
import MailerLite from '@mailerlite/mailerlite-nodejs';


const email = ref("");
const msg = ref();
const showButton = ref(true);

const mailerlite = new MailerLite({
  api_key: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiYjhkMWFjYmRhNGYzZjcxMmJjOTBkZDk1MTVjNmU5NGI0ZWMyMGRjOGVmOGI1ZmJiZTAwYzlhNzMyZjA1MDZkMzkyMWI1OTYxZWQ1YzhkMTciLCJpYXQiOjE3MTgzNjU3OTYuMjEyMDI5LCJuYmYiOjE3MTgzNjU3OTYuMjEyMDMxLCJleHAiOjQ4NzQwMzkzOTYuMjA4OTI2LCJzdWIiOiI5OTcxMzkiLCJzY29wZXMiOltdfQ.ZNgeSrtAD73ppNDKsK39KPkyMwALmU0vYD5vi_QSSgSI8ofdaCeyCNLIHLEEasaOwFx58FPu8E-8ffv6tHJchA3poqPz5_YwB8sqNtSqqWEyQMTBhzm0VADEuUpI584RB7HDK5l33F2-cdB_IY_AhX6odbzYGUApmoZvxSayyEYS9ylm3tTlug1ieFuUJiklX7yWhxIxnVTdiUE1SP1X_IjQLKX0ZekL6Tj_aM3RdWmr9L_ibRA5xTHhgBCWcGZ-imD1JwPj456khUmoongODZ-BEC-t-Xi0I_wQOE7s7r2ia--EDuaPeX9oGLYwY-8iIqualanIT8H7dWOV6WiKXauQgEEEYf3nIwi3YnPh3BBg16CqskTvpSypi1ZBrw174pSSigJcrLbS0igPR52JD2v4ZTV_H0nOnE5PzouYVThRiq3b90dVn7aZPcICKZ7Sujth3qHbcrwj4BcFDhBtlIsODi_2L2r1PKtrjIuhHKmcgaSP0JZK46W6kRLHLjvr5klkyawBmShkCMSRnPJEw2GZQCgZNluZI7pSiVlYTpMvYU7o4F6dKG_mbE6W8xIQyGkvltNcs-NMXl3inf4YOKxTtUbxWxH3ENUuSwv57zpK8JDBi7pY8f3hXxVcAb_btlJsb0jYVli--Bcq4wJT1t9nciCZDVlcY-hFwLp0te0"
});

const validateEmail = (email: String) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const addEmail = async () => {
    const text = document.getElementById("msg") as HTMLElement;
    if (validateEmail(email.value)) {
        msg.value = "Thank you for signing up! You'll get updates soon.";
        text.style.setProperty('color', 'green'); // Set color to green for valid email
        showButton.value = false;
        console.log(email.value);

        // Prepare subscriber data
        const subscriberData = {
            email: email.value,
            subscribed_at: generateDatabaseDateTime(),
        };

        mailerlite.subscribers.createOrUpdate(subscriberData)
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                if (error.response) console.log(error.response.data);
            });
    } else {
        msg.value = "Please enter a valid email";
        text.style.setProperty('color', 'red'); // Set color to red for invalid email
        console.log(email.value);
    }
};

function generateDatabaseDateTime() {
    let datenow = new Date();
    return datenow.toISOString().replace("T"," ").substring(0, 19);
}

</script>

<style>

#hero-wrapper {
    display: flex;
    justify-content: center;
    align-items: start;
    margin: auto;
}

#left-content-wrapper {
    margin: 50px 100px 0 60px;
    justify-content: left;
    height: 599px;
}

#hero-pic {
    width: 700px;
}

#hero-header {
    font-size: 56px;
    width: 574px;
    line-height: 66px;
    margin-bottom: -6px;
}

#hero-subtext {
    font-size: 18px;
    width: 574px;
    line-height: 30px;
    margin-top: 5px;
    margin-bottom: 23px;
}

#get-invite {
    width: 596px;
    height: 72px;
    border-radius: 15px;
    background-color: #64DF99;
    border-style: none;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'Poppins';
    margin-top: 7px;
}

#underline {
    margin: -10px 0 0 65px;
}

@media (max-width: 1450px) {
    #left-content-wrapper {
    margin: 30px 5% 0 60px;
}

#hero-pic {
    width: 550px;
    margin-right: 0px;
}

#hero-header {
    font-size: 48px;
    width: 500px;
    line-height: 55px;
    margin-bottom: -6px;
}

#hero-subtext {
    font-size: 16px;
    width: 500px;
    line-height: 30px;
    margin-top: 5px;
    margin-bottom: 23px;
}

#get-invite {
    width: 500px;
    font-size: 16px;
}

#underline {
    margin: -4px 0 0 50px;
    width: 260px;
}
}

@media (max-width: 1150px) {
    #left-content-wrapper {
    margin: 30px 5% 0 50px;
}

#hero-pic {
    width: 50%;
}

#hero-header {
    font-size: 42px;
    width: 380px;
    line-height: 50px;
    margin-bottom: -8px;
}

#hero-subtext {
    font-size: 14px;
    width: 380px;
    line-height: 30px;
    margin-top: 0px;
    margin-bottom: 23px;
}

#input {
    width: 500px;
}

#get-invite {
    width: 500px;
    font-size: 16px;
}

#underline {
    margin: -5px 0 0 105px;
    width: 230px;
}
}

@media (max-width: 866px) {

#hero-wrapper {
    display: block;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;

}

#left-content-wrapper {
    margin: 30px 5% 0 50px;
    height: 100%;
}

#hero-pic {
    width: 500px;
    text-align: center;
    margin-top: 40px;
}

#hero-header {
    width: 100%;
    text-align: center;
}

#hero-subtext {
    font-size: 14px;
    width: 100%;
    line-height: 30px;
    margin-top: 0px;
    margin-bottom: 23px;
    text-align: center;
}

#get-invite {
    width: 300px;
    font-size: 16px;
}

#underline {
    margin: -5px 0 0 100px;
    width: 230px;
}
}

@media (max-width: 800px) {
#underline {
    margin: -5px 0 0 260px;
}
}

@media (max-width: 637px) {
#underline {
    margin: -5px 0 0 300px;
}
}

@media (max-width: 623px) {
#hero-header {
    font-size: 38px;
    width: 100%;
}
#underline {
    margin: -5px 0 0 250px;
}
}

@media (max-width: 570px) {
#hero-pic {
    width: 100%;
    text-align: center;
}

#hero-header {
    font-size: 32px;
    width: 100%;
    margin-bottom: -15px;
}
#underline {
    margin: -5px 0 0 190px;
    width: 180px;
}
#hero-subtext {
    font-size: 12px;
    width: 100%;
    line-height: 25px;
    margin-top: 0px;
    margin-bottom: 23px;
    text-align: center;
}

#left-content-wrapper {
    margin: auto;
    padding: 0 30px 0 30px;
}
}

@media (max-width: 487px) {

#underline {
    margin: -5px 0 0 230px;
}
}

@media (max-width: 477px) {
#hero-header {
    font-size: 26px;
    margin-bottom: -15px;
    line-height: 40px;
}
#underline {
    margin: -5px 0 0 160px;
    width: 150px;
}
}

@media (max-width: 477px) {
#left-content-wrapper {
    max-width: 350px;
}
#hero-header {
    font-size: 35px;
    margin: auto auto -10px auto;
    line-height: 45px;
}
#underline {
    margin: -5px 0 0 90px;
    width: 190px;
}
}

@media (max-width: 378px) {
/* #left-content-wrapper {
    max-width: 250px;
} */
#hero-header {
    font-size: 32px;
    margin: auto auto -15px auto;
    line-height: 340x;
}
#underline {
    margin: -5px 0 0 70px;
    width: 160px;
}
}

@media (max-width: 351px) {
/* #left-content-wrapper {
    max-width: 250px;
} */
#hero-header {
    font-size: 28px;
    margin: auto auto -15px auto;
    line-height: 340x;
    text-align: le;
}
#underline {
    margin: -5px 0 0 70px;
    width: 160px;
}
}


</style>