<template>
    <div id="container">
        <h1 class="hidden" id="main-heading">Ditch the clunky spreadsheets and complicated platforms.</h1>
        <p  class="hidden" id="subheading">Compadre is designed to eliminate as many moving parts as possible, freeing you up to do more of what you do best: coaching. Tedious administrative tasks don’t need to be a full time job.</p>
        <div id="features-wrapper">
            <div class="card-background" id="create-assignments">
                <img class="img" src="../assets/features1-pictures/pen.svg" />
                <h3 class="features-heading">Create Assignments</h3>
                <p class="features-subheading">Set todos and send fillable forms to keep your clients on track.</p>
            </div>
            <div class="card-background" id="file-sharing">
                <img class="img" src="../assets/features1-pictures/file.svg" />
                <h3 class="features-heading">Seamless File Sharing</h3>
                <p class="features-subheading">Store and share all meeting notes and other files in the same place.</p>
            </div>
            <div class="card-background" id="client-app">
                <img class="img" src="../assets/features1-pictures/phone.svg" />
                <h3 class="features-heading">Client App</h3>
                <p class="features-subheading">Keep your clients up-to-date between sessions, with hassle-free access to all of their information.</p>
            </div>
            <div class="card-background" id="scheduler">
                <img class="img" src="../assets/features1-pictures/calendar.svg" />
                <h3 class="features-heading">Integrated Scheduler</h3>
                <p class="features-subheading">Built-in calendar and scheduler allows you, and your clients to book meetings and upcoming events.</p>
            </div>
            <div class="card-background" id="payments">
                <img class="img" src="../assets/features1-pictures/dollar.svg" />
                <h3 class="features-heading">Pain-free Payments</h3>
                <p class="features-subheading">Client invoicing and payments is made easy with our payment processor powered by Stripe.</p>
            </div>
            <div class="card-background" id="messaging">
                <img class="img" src="../assets/features1-pictures/chat.svg" />
                <h3 class="features-heading">Convenient Messaging</h3>
                <p class="features-subheading">Stay in contact with your clients, with support for audio and video messaging as well.</p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">

</script>

<style>

#container {
    background-image: url("../assets/features1-pictures/background.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
}

#main-heading {
    max-width: 1000px;
    color: #64DF99; 
    font-size: 48px;
    line-height: 60px;
    padding-top: 150px;
    margin: auto;
}

#subheading {
    max-width: 891px;
    font-size: 18px;
    margin: 31px auto auto auto;
}

#features-wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    column-gap: 28px;
    row-gap: 28px;
    margin-top: 76px;
    padding-bottom: 150px;
}

.card-background {
    width: 324px;
    height: 219px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 40px 40px 40px;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.062));
}

.features-heading {
    margin: 22px 0 8px 0;
    font-size: 26px;
    font-weight: 700;
}

.features-subheading {
    margin: 0;
    font-size: 18px;
}

#create-assignments {
    border-radius: 24px 0 0 0;
}

#client-app {
    border-radius: 0 24px 0 0;
}

#scheduler {
    border-radius: 0 0 0 24px;
}

#messaging {
    border-radius: 0 0 24px 0;
}

@media (max-width: 1350px) {
.img {
    width: 50px;
}
.card-background {
    width: 300px;
    height: 219px;
    padding: 25px 25px 25px 25px;
}

.features-heading {
    font-size: 24px;
}

.features-subheading {
    font-size: 16px;
}
}

@media (max-width: 1170px) {
#main-heading {
    max-width: 800px;
    font-size: 45px;
}

#subheading {
    max-width: 750px;
}
#features-wrapper {
    grid-template-columns: auto auto;
}
#file-sharing {
    border-radius: 0 24px 0 0;
}

#payments {
    border-radius: 0 0 0 24px;
}

#client-app {
    border-radius: 0 0 0 0;
}

#scheduler {
    border-radius: 0 0 0 0;
}

}

@media (max-width: 1170px) {
#main-heading {
    max-width: 800px;
    font-size: 45px;
}

#subheading {
    max-width: 750px;
}
#features-wrapper {
    grid-template-columns: auto auto;
}
#file-sharing {
    border-radius: 0 24px 0 0;
}

#payments {
    border-radius: 0 0 0 24px;
}

#client-app {
    border-radius: 0 0 0 0;
}

#scheduler {
    border-radius: 0 0 0 0;
}

#container {
    background-size: 200% 100%;
}

}

@media (max-width: 849px) {
#main-heading {
    max-width: 700px;
    font-size: 40px;
}

#subheading {
    max-width: 600px;
}
.img {
    width: 50px;
}
.card-background {
    width: 250px;
    height: 200px;
    padding: 25px 25px 25px 25px;
}
.features-heading {
    font-size: 20px;
}
.features-subheading {
    font-size: 14px;
}
}

@media (max-width: 708px) {
#container {
    background-size: 500% 100%;
}

#features-wrapper {
    grid-template-columns: auto;
}

#main-heading {
    max-width: 600px;
    font-size: 32px;
    line-height: 45px;
    padding-inline: 35px;
}

#subheading {
    max-width: 550px;
    font-size: 16px;
    padding-inline: 30px;
}
.img {
    width: 50px;
}
.card-background {
    width: 70%;
    height: 175px;
    padding: 25px 25px 25px 25px;
    margin: auto;
}
.features-heading {
    font-size: 22px;
}
.features-subheading {
    font-size: 16px;
}
#create-assignments, #file-sharing, #client-app, #scheduler, #payments, #messaging {
    border-radius: 24px;
}
}

@media (max-width: 615px) {
.features-heading {
    font-size: 18px;
    line-height: 20px;
}
.features-subheading {
    font-size: 14px;
}
}

@media (max-width: 360px) {
#main-heading {
    font-size: 28px;
    line-height: 40px;
}
}

</style>